<template>
    <div class="bgcImg">
        <div class="img-father">
            <div style="width:100%;height:100%;display: flex;justify-content: space-around;flex-wrap: wrap;margin-top:40px;">
                <div class="imgbox" v-for="(item,index) in dataArr" :key="index" @click="emitClick(index)">
                    <div>
                        {{item.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { bigdata } from "../../api";
    export default {
        data() {
            return {
                show: -1,
                objData: [
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },
                    {
                        title: "和唐雅苑",
                        text: "党群服务中心"
                    },

                ],
                urlToken: "ea2efee83d9d7c69348716ed1c560d49",
                dataArr: []
            }
        },
        created() {
            let _this = this;
            document.onkeydown = function () {
                let key = window.event.keyCode;
                if (key == 38) {
                    _this.websocket.send(JSON.stringify({ direction: "上", token: _this.urlToken }));
                } else if (key == 40) {
                    _this.websocket.send(JSON.stringify({ direction: "下", token: _this.urlToken }));
                } else if (key == 37) {
                    _this.websocket.send(JSON.stringify({ direction: "左", token: _this.urlToken }));
                } else if (key == 39) {
                    _this.websocket.send(JSON.stringify({ direction: "右", token: _this.urlToken }));
                }
            };
        },
        mounted() {
            this.initWebsocket('ws://8.142.168.191:8001');
            this.getData();
        },
        methods: {
            emitClick(index) {
                this.show = index;
                this.websocket.send(JSON.stringify({ id: index, token: this.urlToken }));
            },
            initWebsocket(wbUrl) {
                this.websocket = new WebSocket(wbUrl);
                this.websocket.onopen = () => {
                    this.websocket.send(JSON.stringify({ token: this.urlToken }))
                };
                this.websocket.onmessage = this.websocketMessage;
                this.websocket.onerror = this.websocketError;
            },
            websocketMessage() {

            },
            getData() {
                bigdata().then(res => {
                    console.log(res.data.list);
                    this.dataArr = res.data.list;
                })
            }
        }
    }
</script>
<style>
    #box {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -60%);

    }

    body,
    html {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .bgcImg {
        background-image: url("../../assets/bgc.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100%;
    }

    .img-father {
        width: 93%;
        height: 100%;
        background: url("../../assets/box.png");
        background-size: 100% 100%;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        display: flex;
        flex-direction: row;
        padding: 80px 50px 100px 50px;
        padding-left: 75px;
        box-sizing: border-box;
        flex-wrap: wrap;
    }

    .imgbox {
        width: 15%;
        height: 170px;
        background: url("../../assets/button.png");
        background-size: 100% 100%;
        text-align: center;
        color: #fff;
        margin-right: 25px;
        /* margin-top: 3vh; */
    }

    .imgbox div {
        margin: 0 auto;
        margin-top: 45px;
        font-weight: bold;
        width: 100px;
        line-height: 5vh;
    }
</style>